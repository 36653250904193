import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Mann Pools`}</h1>
    <p>{`I designed the layout where the content is easily readable and comprehensible. I used the latest practices of HTML, Sass/CSS, Javascript, and some PHP to create this responsive website.`}</p>
    <p>{`Below you see three view-ports, small, medium, and large. When making websites I use the mobile first approach and this leaves no device size out. I start with the smallest view-port, then expand and create breakpoints when necessary.`}</p>
    <p>{`Visit `}<a href="http://mannpools.com/" target="_blank">{`Mann Pools`}</a></p>
    <div className="pure-g">
  <div className="pure-u-1 pure-u-md-4-24">
    <h3>Small Viewport</h3>
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fmannpools-sm.jpg?alt=media&token=afbc9ca2-5b41-43ae-bb79-7b6083cdd053" width="335" height="2625" alt="small viewport" />
  </div>
  <div className="pure-u-1 pure-u-md-6-24">
    <h3>Medium Viewport</h3>
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fmannpools-md.jpg?alt=media&token=4f638367-ea76-4f79-9ce7-6393e96ff46f" width="793" height="1963" alt="medium viewport" />
  </div>
  <div className="pure-u-1 pure-u-md-14-24">
    <h3>Large Viewport</h3>
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fmannpools-lg.jpg?alt=media&token=8406466e-77ae-4162-b544-0f493c67ab70" width="900" height="1217" alt="large viewport" />
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      